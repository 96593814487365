<template>
  <div id="StuProject">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.schshi" show-action placeholder="请输入项目名称或代码">
        <template #action>
          <div @click="toQuery">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text">
              {{row.schshiName}}
            </span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text" style="width:90%">
              <van-icon name="clock-o" size="14" /> <span style="letter-spacing: 0px;">{{row.startTime }}</span> 至 <span style="letter-spacing: 0px;">{{ row.endTime}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="apps-o" size="14" /> 项目类型: <span>{{row.typeNo ==='jxj'? "奖学金":'助学金'}}</span>
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.status === '1'" class="audit success-color b-color-success">已开启</span>
            <span v-else class="audit warning-color b-color-warning">未开启</span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <van-button class="table-del-btn" disabled @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
          <template #right-icon>
            <span @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { isDuringDate, getTermYear, getTerm } from "@/utils/index";
import { getList } from "@/api/modules/award/stujxj";
import { mapGetters } from "vuex";
import { getAwardById } from '@/api/modules/jz/project'

export default {
  name: "StuProject",
  dicts: ["xj_xqgl"],
  mixins: [initData],
  data() {
    return {
      url: "",
      value: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {

  },
  methods: {
    beforeInit() {
      this.url = "/api/award/project";
      this.sort = ["createTime,asc", "id,desc"];
      return true;
    },
    toEdit(data) {
      this.$router.push({
        path: "/xsc/jz/project/form",
        query: { code: data.id },
      });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xsc/jz/project/detail",
        query: { code: data.id },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/student/jzapply.scss';
</style>
